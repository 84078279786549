import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Controller } from "react-hook-form";
import Button from "../../../../Components/Button/Button";
import useManualSubscriptionModal from "./useManualSubscriptionModal";

const ManualSubscriptionModal = ({ userId, open, onClose, fetchUsers }) => {
  const {
    themeProv,
    handleSubmit,
    onSubmit,
    errors,
    control,
    handleClose,
    plans,
  } = useManualSubscriptionModal(onClose, fetchUsers, userId);

  return (
    <ThemeProvider theme={themeProv}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="terms-and-conditions-dialog"
        aria-describedby="terms-and-conditions-dialog-description"
      >
        <DialogTitle>Activate Subscription:</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              mt={2}
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} md={8}>
                <FormControl
                  fullWidth
                  variant="filled"
                  error={Boolean(errors.date)}
                >
                  <Controller
                    name="date"
                    control={control}
                    rules={{ required: "Date is required" }}
                    render={({ field }) => (
                      <DatePicker {...field} fullWidth label="Date" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  name="planId"
                  control={control}
                  rules={{ required: "Plan is required" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <FormControl
                        fullWidth
                        variant="filled"
                        error={Boolean(error)}
                      >
                        <InputLabel id="planId-label">Plan</InputLabel>
                        <Select
                          labelId="planId-label"
                          value={value}
                          label="Plan"
                          onChange={onChange}
                        >
                          {plans?.length !== 0 &&
                            plans.map((plan) => (
                              <MenuItem key={plan.value} value={plan.value}>
                                {plan.label}
                              </MenuItem>
                            ))}
                        </Select>
                        {error && (
                          <FormHelperText error>
                            {error ? error.message : null}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ px: 6, py: 4 }}>
          <Button text="Activate" onClick={handleSubmit(onSubmit)} />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ManualSubscriptionModal;
