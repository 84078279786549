import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import LeftArrowIcon from "../Icons/LeftArrowIcon";
import RightArrowIcon from "../Icons/RightArrowIcon";

const SliderArrows = (props) => {
  const { prev, next } = props;
  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
      mt={1}
    >
      <Grid item>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
          data-cursor-text="Previous"
          onClick={prev}
        >
          <LeftArrowIcon />
        </Box>
      </Grid>
      <Grid
        item
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography fontSize={"1.5rem"}>Swipe</Typography>
      </Grid>
      <Grid item>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
          data-cursor-text="Next"
          onClick={next}
        >
          <RightArrowIcon />
        </Box>
      </Grid>
    </Grid>
  );
};

export default SliderArrows;
