import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useContext, useMemo, useState } from "react";
import SecImage from "../../Assets/image section.png";
import Button from "../../Components/Button/Button";
import HomePageSlider from "../../Components/HomePageSlider/HomePageSlider";
import { TelegramIcon, WhatsAppIcon } from "../../Components/Icons";
import LinearProgressBar from "../../Components/LinearProgressBar";
import LatestIdeaSkeleton from "../../Components/Skeleton card/LatestIdeaSkeleton";
import SkeletonSlider from "../../Components/Skeleton card/SkeletonSlider";
import SlideUp from "../../Components/SlideUp";
import AuthContext from "../Auth/AuthContext";
import HeaderText from "./components/HeaderText";
import LatestIdeaCardDescription from "./components/LatestIdeaCardDescription";
import LatestIdeaCardDummy from "./components/LatestIdeaCardDescription/LatestIdeaCardDummy";
import SebiConditionsModal from "./components/SebiConditionsModal";
import "./style.scss";
import useHomePage from "./useHomePage";
const REACT_APP_TELEGRAM_LINK = process.env.REACT_APP_TELEGRAM_LINK;
const REACT_APP_WHATSAPP_LINK = process.env.REACT_APP_WHATSAPP_LINK;

const HomePage = () => {
  const { isLoading, isSuccess, onClickHandler, latestIdea, homepageSections } =
    useHomePage();
  const { isSubscribed, isAuthenticated } = useContext(AuthContext);
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  const mediumHeaderFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "25px" : "40px") : "55px"),
    [isMdDown, isSmDown],
  );
  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  const smallParagraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "16px" : "21px") : "24px"),
    [isMdDown, isSmDown],
  );
  const dateOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const unauthorizedIdeaBySubscription = useMemo(
    () => latestIdea?.paid === "Paid" && !isSubscribed,
    [latestIdea, isSubscribed],
  );

  const [open, setOpen] = useState(false);
  const handleModalOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleModalClose = useCallback(() => {
    setOpen(false);
  }, []);
  return (
    <Box>
      <SebiConditionsModal open={open} onClose={handleModalClose} />
      {!isAuthenticated && (
        <Box
          height={!isSmDown && "90vh"}
          my={isSmDown ? 5 : 0}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box width={isSmDown ? "90vw" : "75vw"}>
            <Grid
              container
              overflow="hidden"
              display="flex"
              flexDirection="column"
            >
              <Grid
                container
                display="flex"
                flexDirection="column"
                alignItems="start"
                justifyContent="center"
                spacing={isSmDown ? 3 : 6}
              >
                <Grid item>
                  <HeaderText
                    headerFontSize={headerFontSize}
                    textList={[
                      "Presenting one",
                      "fundamentally strong",
                      "company every week",
                    ]}
                  />
                </Grid>

                <Grid item>
                  <Typography
                    fontSize={isSmDown && mediumHeaderFontSize}
                    fontWeight={500}
                    lineHeight={1.2}
                  >
                    <SlideUp>Join us for weekly updates</SlideUp>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container display="flex" spacing={2} mt={1}>
              <Grid item>
                <a href={REACT_APP_WHATSAPP_LINK}>
                  <Button>{isSmDown ? <WhatsAppIcon /> : "WhatsApp"}</Button>
                </a>
              </Grid>
              <Grid item>
                <a href={REACT_APP_TELEGRAM_LINK}>
                  <Button>{isSmDown ? <TelegramIcon /> : "Telegram"}</Button>
                </a>
              </Grid>
            </Grid>
            <Grid
              container
              pt={isSmDown ? 4 : 8}
              display={"flex"}
              justifyContent={isSmDown ? "start" : "end"}
            >
              <Grid item>
                <Typography
                  onClick={handleModalOpen}
                  fontWeight={500}
                  sx={{ cursor: "pointer" }}
                  lineHeight={1}
                >
                  <SlideUp>We are not sebi registered</SlideUp>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}

      {isLoading && !isSuccess ? (
        <>
          <LinearProgressBar />
          <LatestIdeaSkeleton />
          <SkeletonSlider />
          <SkeletonSlider />
          <SkeletonSlider />
        </>
      ) : (
        <>
          {latestIdea && (
            <>
              <Box
                marginY={isLgDown ? "20vh" : isSmDown ? 4 : undefined}
                height={isLgDown ? undefined : "100vh"}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box width={isSmDown ? "90vw" : "75vw"}>
                  <Grid
                    container
                    display="flex"
                    flexDirection="column"
                    spacing={8}
                  >
                    <Grid item overflow="hidden">
                      <Typography
                        fontWeight={600}
                        lineHeight={1}
                        fontSize={headerFontSize}
                      >
                        <SlideUp>Latest Darkhorsestock Article</SlideUp>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      onClick={onClickHandler}
                      data-cursor-text="Read Report"
                      sx={{ cursor: "pointer" }}
                    >
                      <Grid
                        container
                        display="flex"
                        justifyContent="center"
                        spacing={8}
                      >
                        {isLgDown ? (
                          <>
                            <Grid item lg={6} md={12} sm={12}>
                              <img
                                src={
                                  latestIdea &&
                                  latestIdea.image &&
                                  latestIdea.image.length
                                    ? latestIdea.image
                                    : SecImage
                                }
                                alt="Hero"
                                className="hero-img"
                              />
                            </Grid>
                            <Grid item lg={6} md={12} sm={12}>
                              {unauthorizedIdeaBySubscription ||
                              !isAuthenticated ? (
                                <LatestIdeaCardDummy
                                  isAuthenticated={isAuthenticated}
                                  dateOptions={dateOptions}
                                  mediumHeaderFontSize={mediumHeaderFontSize}
                                  paragraphFontSize={paragraphFontSize}
                                  smallParagraphFontSize={
                                    smallParagraphFontSize
                                  }
                                />
                              ) : (
                                <LatestIdeaCardDescription
                                  dateOptions={dateOptions}
                                  latestIdea={latestIdea}
                                  mediumHeaderFontSize={mediumHeaderFontSize}
                                  paragraphFontSize={paragraphFontSize}
                                  smallParagraphFontSize={
                                    smallParagraphFontSize
                                  }
                                />
                              )}
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid
                              position="relative"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              item
                              lg={6}
                              md={12}
                              sm={12}
                            >
                              {unauthorizedIdeaBySubscription ||
                              !isAuthenticated ? (
                                <LatestIdeaCardDummy
                                  isAuthenticated={isAuthenticated}
                                  dateOptions={dateOptions}
                                  mediumHeaderFontSize={mediumHeaderFontSize}
                                  paragraphFontSize={paragraphFontSize}
                                  smallParagraphFontSize={
                                    smallParagraphFontSize
                                  }
                                />
                              ) : (
                                <LatestIdeaCardDescription
                                  dateOptions={dateOptions}
                                  latestIdea={latestIdea}
                                  mediumHeaderFontSize={mediumHeaderFontSize}
                                  paragraphFontSize={paragraphFontSize}
                                  smallParagraphFontSize={
                                    smallParagraphFontSize
                                  }
                                />
                              )}
                            </Grid>
                            <Grid item lg={6} md={12} sm={12}>
                              <img
                                src={
                                  latestIdea &&
                                  latestIdea.image &&
                                  latestIdea.image.length
                                    ? latestIdea.image
                                    : SecImage
                                }
                                alt="Hero"
                                className="hero-img"
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box width="90vw">
          <Grid container display="flex" flexDirection="column">
            {homepageSections &&
              homepageSections.length &&
              homepageSections.map(
                (homepageSection) =>
                  homepageSection.companyList?.length !== 0 && (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      key={homepageSection.title}
                    >
                      <Box my={isSmDown && 4} height={!isSmDown && "100vh"}>
                        <Grid
                          container
                          flexDirection="column"
                          display="flex"
                          alignItems="start"
                          justifyContent="center"
                          spacing={8}
                        >
                          <Grid item xs={12} md={12} lg={12}>
                            <Typography
                              fontWeight={600}
                              lineHeight={1}
                              fontSize={headerFontSize}
                              pl={isMdDown ? 0 : 10}
                            >
                              <SlideUp capitalize>
                                {homepageSection.title}
                              </SlideUp>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={12} lg={12}>
                            <HomePageSlider homepageSection={homepageSection} />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ),
              )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
export default HomePage;
