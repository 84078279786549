import React from "react";

const TelegramIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8109 3.4965C22.5301 3.51733 22.2544 3.58322 21.9946 3.69163H21.9911C21.7417 3.7905 20.5561 4.28925 18.7536 5.04525L12.2943 7.76563C7.65943 9.71688 3.1033 11.6384 3.1033 11.6384L3.15755 11.6174C3.15755 11.6174 2.84343 11.7206 2.5153 11.9455C2.31213 12.0739 2.13759 12.2428 2.00255 12.4416C1.84155 12.6779 1.71205 13.0393 1.76018 13.4129C1.83893 14.0446 2.24843 14.4235 2.54243 14.6326C2.83993 14.8444 3.12343 14.9432 3.12343 14.9432H3.13043L7.40305 16.3826C7.59468 16.9978 8.70505 20.6483 8.97193 21.4891C9.12943 21.9914 9.28255 22.3055 9.47418 22.5453C9.56576 22.6678 9.6763 22.7701 9.8058 22.8524C9.87307 22.8928 9.94547 22.924 10.0211 22.9451L9.9773 22.9346C9.99043 22.9381 10.0009 22.9486 10.0106 22.9521C10.0456 22.9618 10.0692 22.9653 10.1138 22.9722C10.7902 23.177 11.3336 22.757 11.3336 22.757L11.3642 22.7325L13.8868 20.4356L18.1148 23.6793L18.2111 23.7204C19.0922 24.1071 19.9847 23.8919 20.4563 23.5121C20.9314 23.1298 21.1161 22.6406 21.1161 22.6406L21.1467 22.5619L24.4139 5.824C24.5067 5.411 24.5303 5.02425 24.4279 4.64888C24.3229 4.26871 24.079 3.94179 23.7446 3.73275C23.4641 3.56213 23.1388 3.47981 22.8109 3.4965ZM22.7226 5.29025C22.7191 5.34538 22.7296 5.33925 22.7051 5.44513V5.45475L19.4684 22.0185C19.4544 22.0421 19.4308 22.0938 19.3661 22.1454C19.2978 22.1996 19.2436 22.2338 18.9592 22.1209L13.7879 18.1563L10.6642 21.0035L11.3204 16.8123L19.7694 8.93725C20.1177 8.6135 20.0013 8.54525 20.0013 8.54525C20.0258 8.148 19.4754 8.42888 19.4754 8.42888L8.82143 15.029L8.81793 15.0115L3.71143 13.2921V13.2886L3.6983 13.286L3.72455 13.2755L3.75255 13.2615L3.77968 13.2519C3.77968 13.2519 8.3393 11.3304 12.9742 9.37913C15.2947 8.40175 17.6327 7.41738 19.4308 6.65788C20.4972 6.209 21.5647 5.76275 22.6333 5.31913C22.7051 5.29113 22.6709 5.29025 22.7226 5.29025Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TelegramIcon;
