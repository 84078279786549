import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  ThemeProvider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Controller } from "react-hook-form";
import Button from "../../../../Components/Button/Button";
import useCsvFormModal, { SubscriptionEnum } from "./useCsvFormModal";

const CsvFormModal = ({ open, onClose }) => {
  const { themeProv, handleSubmit, onSubmit, control, watch, plans } =
    useCsvFormModal(onClose);

  return (
    <ThemeProvider theme={themeProv}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="terms-and-conditions-dialog"
        aria-describedby="terms-and-conditions-dialog-description"
      >
        <DialogTitle>Get users:</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              mt={2}
              container
              display="flex"
              justifyContent="start"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} md={8}>
                <FormLabel>Users who have active subscriptions</FormLabel>
                <Controller
                  name="isSubscribed"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      value={field.value ?? SubscriptionEnum.BOTH}
                      onChange={(e) => field.onChange(e.target.value)}
                      row
                    >
                      <FormControlLabel
                        value={SubscriptionEnum.IS_SUBSCRIBED}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={SubscriptionEnum.NOT_SUBSCRIBED}
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value={SubscriptionEnum.BOTH}
                        control={<Radio />}
                        label="Both"
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl fullWidth variant="filled">
                  <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        fullWidth
                        label="Expiration date"
                        disabled={
                          watch("isSubscribed") !==
                          SubscriptionEnum.IS_SUBSCRIBED
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8}>
                {plans && plans?.length !== 0 && (
                  <Controller
                    name="planId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <FormControl fullWidth variant="filled">
                          <InputLabel id="planId-label">Plan</InputLabel>
                          <Select
                            labelId="planId-label"
                            value={value}
                            disabled={
                              watch("isSubscribed") !==
                              SubscriptionEnum.IS_SUBSCRIBED
                            }
                            label="Plan"
                            onChange={onChange}
                            variant="filled"
                          >
                            {plans.map((plan) => (
                              <MenuItem key={plan.value} value={plan.value}>
                                {plan.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  />
                )}
              </Grid>
              {watch("planId") === "N/A" && (
                <>
                  <Grid item xs={12} md={8}>
                    <FormControlLabel
                      control={
                        <Controller
                          name="darkHorseStocksUSA"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              disabled={
                                watch("isSubscribed") !==
                                SubscriptionEnum.IS_SUBSCRIBED
                              }
                              checked={field.value ?? false}
                              {...field}
                            />
                          )}
                        />
                      }
                      label="Users who are subscribed to darkHorseStocksUSA plan"
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <FormControlLabel
                      control={
                        <Controller
                          name="darkHorseStocks"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              disabled={
                                watch("isSubscribed") !==
                                SubscriptionEnum.IS_SUBSCRIBED
                              }
                              checked={field.value ?? false}
                              {...field}
                            />
                          )}
                        />
                      }
                      label="Users who are subscribed to darkHorseStocks plan"
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <FormControlLabel
                      control={
                        <Controller
                          name="PMS"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              disabled={
                                watch("isSubscribed") !==
                                SubscriptionEnum.IS_SUBSCRIBED
                              }
                              checked={field.value ?? false}
                              {...field}
                            />
                          )}
                        />
                      }
                      label="Users who are subscribed to PMS plan"
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <FormControlLabel
                      control={
                        <Controller
                          name="fundalysis"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              disabled={
                                watch("isSubscribed") !==
                                SubscriptionEnum.IS_SUBSCRIBED
                              }
                              checked={field.value ?? false}
                              {...field}
                            />
                          )}
                        />
                      }
                      label="Users who are subscribed to fundalysis plan"
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={8}>
                <FormControlLabel
                  control={
                    <Controller
                      name="hasPhone"
                      control={control}
                      render={({ field }) => (
                        <Checkbox checked={field.value ?? false} {...field} />
                      )}
                    />
                  }
                  label="Users who have phone number"
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControlLabel
                  control={
                    <Controller
                      name="hasEmail"
                      control={control}
                      render={({ field }) => (
                        <Checkbox checked={field.value ?? false} {...field} />
                      )}
                    />
                  }
                  label="Users who have email address"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ px: 6, py: 4 }}>
          <Button text="Export CSV" onClick={handleSubmit(onSubmit)} />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default CsvFormModal;
